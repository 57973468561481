<template>
  <div class="columns is-marginless" style="height: 100%">
    <div
      class="column has-background-primary columns is-flex-direction-column
             is-centered is-marginless is-hidden-mobile"
    >
      <img src="" alt="" />
    </div>
    <div
      class="column columns is-marginless is-flex-direction-column is-justify-content-space-between"
      style="height: 100%"
    >
      <div class="column is-narrow">
        <h1 class="has-text-centered is-size-2 has-text-weight-medium">
          Telemedecine
        </h1>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import firebase from 'firebase';

export default {
  name: 'ValidateEmail',
  computed: {
    ...mapGetters({
      userEmail: 'email',
      userAuth: 'auth',
    }),
  },
  methods: {
    ...mapMutations({
      setEmail: 'SET_EMAIL',
      setUserId: 'SET_USER_ID',
    }),

    linkAccount() {
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        let email = this.userEmail;
        if (!this.userEmail) {
          // eslint-disable-next-line no-alert
          email = window.prompt('Please provide your email for confirmation');
        }
        const loadingComponent = this.$buefy.loading.open();
        /** Wait a bit for the firebase user auth to be set */
        setTimeout(async () => {
          try {
            if (!this.userAuth) {
              this.$router.push({ name: 'Login' });
              return;
            }
            const credential = firebase.auth
              .EmailAuthProvider.credentialWithLink(email, window.location.href);
            await this.userAuth.linkWithCredential(credential);
            this.$buefy.toast.open({
              message: 'Anonymous account upgraded',
              type: 'is-primary',
            });
            this.$router.push({ name: 'Profile' });
          } catch (error) {
            this.$buefy.toast.open({
              message: error.message,
              type: 'is-danger',
            });
          } finally {
            loadingComponent.close();
          }
        }, 5000);
      } else {
        this.$router.push({ name: 'Home' });
      }
    },
  },
  mounted() {
    this.linkAccount();
  },
};
</script>
